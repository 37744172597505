import SpeedIcon from '@mui/icons-material/Speed';
import FeatureCard from './FeatureCard';
import FeatureCardBulb from './FeatureCardBulb';
import FeatureCardBolt from './FeatureCardBolt';
import FeaturecardDb from './FeatureCardDb';

export default function FeatureComponent(){


    return(
        <div className="flex flex-col bg-gray-50 dark:bg-slate-800 sm:px-5">

            <div
                className="flex justify-center flex-row"
            >
                <FeatureCard   title='Writes Upside-Down' text='extra text extra text extra text extra text extra text extra text extra text' />
                <FeatureCardBolt   title='Writes Upside-Down' text='extra text extra text extra text extra text extra text extra text extra text' />
                
            </div>
            <div
                className="flex justify-center flex-row"
            >
                <FeatureCardBulb   title='Writes Upside-Down' text='extra text extra text extra text extra text extra text extra text extra text' />
                <FeaturecardDb   title='Writes Upside-Down' text='extra text extra text extra text extra text extra text extra text extra text' />
                
            </div>
        </div>
    );
}