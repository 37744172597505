import BoltIcon from '@mui/icons-material/Bolt';

export default function FeatureCardBolt(props){


    return(
        <div class="bg-white dark:bg-slate-800 rounded-lg px-6 py-8">
            <div className='visible sm:hidden'>
                <div className="flex flex-row justify-center ">
                <BoltIcon 
                    className='text-slate-500 dark:text-slate-200'
                    style={{
                        fontSize:100,

                    }}
                />
                </div>

                <h3 class="text-slate-900 dark:text-white mt-5 text-base font-medium tracking-tight">{props.title}</h3>
                <p class="text-slate-500 dark:text-slate-400 mt-2 text-sm">
                    {props.text}
                </p>
            </div>
            <div className='hidden sm:block'>
                <div className="flex flex-row justify-center ">
                <BoltIcon 
                    className='text-slate-500 dark:text-slate-200'
                    style={{
                        fontSize:100,
                        
                    }}
                />
                </div>
                <div className='flex flex-col justify-center items-center text-center'>

                <h3 class="text-slate-900 dark:text-white mt-5 text-base font-medium tracking-tight">{props.title}</h3>
                <p class="text-slate-500 dark:text-slate-400 mt-2 text-sm">
                    {props.text}
                </p>
                </div>

            </div>

        </div>
    );
}; //#fde047