function Contact(){
    return(
      <div className="bg-gray-50 dark:bg-slate-800">
          <h1 className="text-3xl font-bold ">
          Contact
        </h1>
      </div>
    );
}

export default Contact;