import logo from './logo.svg';
import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import NavMenu from './components/NavMenu';
import Home from './pages/Home';
import About from './pages/about';
import Contact from './pages/contact'
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  return (

    <BrowserRouter>
    <Header/>
    <div className="flex justify-center ">
      <Routes>
            <Route path='/' element={<Home />}/>
            <Route path='/about' element={<About />}/>
            <Route path='/contact' element={<Contact />}/>
        </Routes>

    </div>

    <Footer/>
    </BrowserRouter>


  );
}

export default App;
