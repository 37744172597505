


import image from "./../../images/filler1.png"

export default function TextOverImage(){
    return(
        // <div>
        //     <div className="flex flex-col items-center bg-white  rounded-lg shadow md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
        //         <img className="object-cover w-full rounded-t-lg h-96 md:h-auto md:w-48 md:rounded-none md:rounded-s-lg" src={image} alt="logo"/>
        //         <div className="flex flex-col justify-between p-4 leading-normal">
        //             <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Maatwerk software</h5>
        //             <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">Websites, Apps, Systeem koppelingen</p>
        //         </div>
        //     </div>
        // </div>  
<div className="flex justify-center">
    <div class="relative w-[100%]">
        <img src={image}/>
        <div class="absolute bottom-0 left-0  px-4 py-2 opacity-100">
            <h3 class="text-xl text-white font-bold">
                Experts in software</h3>
            <p class="mt-2 text-sm text-gray-100">Een oplossing voor uw bedrijf
            </p>
        </div>
    </div>      
</div>
  
        
    );
}