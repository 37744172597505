import { MenuItem } from "@mui/material";
import { Link } from "react-router-dom";

export default function MenuList(){


    return(
        <div className="flex mr-5">
            <div className="flex-none w-20 dark:text-gray-300">
                <MenuItem
                    component={Link} to='/'
                >
                Home
                </MenuItem>
            </div>
            <div className="flex-none w-20 dark:text-gray-300">
                <MenuItem
                    component={Link} to='/about'
                >
                Over
                </MenuItem>
            </div>
            <div className="flex-none w-20 dark:text-gray-300">
                <MenuItem
                    component={Link} to='/contact'
                >
                Contact
                </MenuItem>
            </div>
        </div>
    );
};