import ThemeSwitcher from "../components/ThemeSwitcher";
import ImageCard from "../components/content/ImageCard";
import IntroCard from "../components/content/IntroCard";
import LogoCard from "../components/content/LogoCard";
import TextOverImage from "../components/content/TextOverImage";
import Testimonials from "../components/content/Testimonials";
import FeatureComponent from "../components/content/FeaturesComponent";
import { Alert } from "@material-tailwind/react";



export default function Home(){

    return(
        <div className="flex flex-col bg-gray-50 dark:bg-slate-800 sm:px-5">
            <div
                className="flex items-center justify-center"
            >
                           <TextOverImage></TextOverImage>      
            </div>


            <div className="flex items-center justify-center flex-row py-10 ">
                   <p className="text-xl text-black dark:text-white font-bold">text</p>

            </div>   
            <div className="flex items-center justify-center flex-row">
                    <IntroCard/>

            </div>       
            <div className="flex items-center justify-center flex-row">
                    <FeatureComponent/>

            </div>     
            <div className="flex items-center flex-row">

                <div className="flex-1">
                    <LogoCard/>

                </div>
                <div className="flex-1">
                    <LogoCard/>
                    <LogoCard/>
                </div>
                
            </div>
            <div className="flex item-center flex-row">
                <Testimonials/>
            </div>
        </div>

    );
}