
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import React, { useState, useEffect } from 'react';

export default function FeatureCardBulb(props){

    const [darkMode, setDarkMode] = useState(false);
  
    useEffect(() => {
      const isDarkMode = localStorage.getItem('darkMode') === 'true';
      setDarkMode(isDarkMode);
    }, []);
  
    useEffect(() => {
      document.documentElement.classList.toggle('dark', darkMode);
      localStorage.setItem('darkMode', darkMode);
    }, [darkMode]);
  
    const toggleDarkMode = () => {
      setDarkMode((prevMode) => !prevMode);
    };
  
    // return (
    //     <button
    //       onClick={toggleDarkMode}
    //       className={`px-4 py-2 rounded-full ${
    //         darkMode ? 'bg-yellow-400' : 'bg-gray-800'
    //       } ${
    //         darkMode ? 'text-gray-900' : 'text-white'
    //       } transition-colors duration-200`}
    //     >
    //       {darkMode ? 'Light Mode' : 'Dark Mode'}
    //     </button>
    //   );


    return(
        <div class="bg-white dark:bg-slate-800 rounded-lg px-6 py-8">
            <div className='visible sm:hidden'>
                <div className="flex flex-row justify-center ">
                <LightbulbIcon 
                    onClick={toggleDarkMode}
                    className='text-yellow-300 dark:text-slate-500'
                    style={{
                        fontSize:100,
                    }}
                />
                </div>

                <h3 class="text-slate-900 dark:text-white mt-5 text-base font-medium tracking-tight">{props.title}</h3>
                <p class="text-slate-500 dark:text-slate-400 mt-2 text-sm">
                    {props.text}
                </p>
            </div>
            <div className='hidden sm:block'>
                <div className="flex flex-row justify-center ">
                <LightbulbIcon 
                    onClick={toggleDarkMode}
                    className='text-yellow-300 dark:text-slate-500'
                    style={{
                        fontSize:100,
                    }}
                />
                </div>
                <div className='flex flex-col justify-center items-center text-center'>

                <h3 class="text-slate-900 dark:text-white mt-5 text-base font-medium tracking-tight">{props.title}</h3>
                <p class="text-slate-500 dark:text-slate-400 mt-2 text-sm">
                    {props.text}
                </p>
                </div>

            </div>

        </div>
    );
}; //#fde047