import { Navigate, redirect } from "react-router-dom";
import MenuList from "./MenuList";
import NavMenu from "./NavMenu";
import React from 'react';
import { useNavigate } from "react-router-dom";


export default function Header(){
    const navigate = useNavigate();

    const handleClick = () => {
        navigate("/");
    }
    
    return(
        <div className="bg-gray-200 dark:bg-slate-900">
        <header className="flex h-20 items-center justify-center mx-6 "> 
            <div className="flex-none"
                onClick={handleClick}
            >
                <div className="flex items-center">
                    <div className="mr-2 h-4 w-4 bg-blue-600">

                    </div>
                    <div>
                        <h1 className="text-2xl font-bold font-sans dark:text-white">Keuning</h1>
                    </div>
                    <div className="ml-1 mt-4">
                        <h1 className="text-sm font-sans dark:text-gray-300">Software</h1>
                    </div>
                </div>

            </div>
            <div className="flex-1 ">

            </div>
            <div className="flex-none  sm:hidden">
                <NavMenu/>
            </div>
            <div className="hidden sm:flex items-center ">
                <MenuList/>
            </div>
        </header>
        </div>

    );
};